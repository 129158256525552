@tailwind base;
@tailwind components;
@tailwind utilities;


#tester-wrapper {
  padding: 1rem 2rem;
}

#tester {
  margin: 0;
  font-weight: 900;
  font-size: 10rem;
  color: #FFFFFF;
  -webkit-user-select: none;
  user-select: none;
}

/* WebKit */
@supports (-webkit-backdrop-filter: blur(0)) {
  #tester {
	filter: brightness(10);
	-webkit-backdrop-filter: brightness(1); 
  }
}

/* Blink */
@supports (backdrop-filter: blur(0)) {
  #tester {
  	filter: brightness(1);
	backdrop-filter: brightness(10); 
	text-shadow: 0 0 10px #FFFFFF;
  }
}

li {
  margin-block-start: 0.5em;
}

#info {
  max-width: 55rem;
  padding: 0 1rem;
}

#info p {
  margin: 0;
  margin-block-start: 1rem;
  margin-block-end: 1rem;
  line-height: 1.5;
}

.imageContainer {
	float: left;
}


video {
  position: fixed;
  width: 1px;
  height: 1px;

  top: 0;
  right: auto;
  bottom: auto;
  left: 0;
}

video:nth-of-type(2) {
  top: 0;
  right: 0;
  bottom: auto;
  left: auto;
}

video:nth-of-type(3) {
  top: auto;
  right: auto;
  bottom: 0;
  left: 0;
}

#floatingTopBar {
  position: sticky;
  z-index: 9999;
  top: 0;
}